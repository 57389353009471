import { useRef, useState, type FC } from 'react'

import styled from '@emotion/styled'

import ColumnTrack from '@segment/matcha/components/ColumnTrack'
import useModal from '@segment/matcha/components/Modal/useModal'
import Button from '@segment/matcha/components/Button'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'
import RichText from '@segment/matcha/recipes/RichText'

import type { SpeakerWithSessions } from '@segment/api/staticSiteBackend/cdpWeek2024'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl/getAbsoluteUrl'

import { usePageConfig } from '@segment/contexts/page'

import SpeakerModal from '../../../components/SpeakerModal'
import SpeakerCard from '../../../components/SpeakerCard'
import useDomainPath from '../../../utils/useDomainPath'

const copy = {
  overline: `Meet Our Speakers`,
  title: `Unveil new insights and learn from experts`,
  description: `From building a best-in-class data infrastructure to delivering intelligent customer engagement across every channel, tune in to hear from business leaders and industry experts on how trusted data and real-time customer profiles are enabling faster innovation, seamless customer experiences and personalized customer connections that drive loyalty.`,
  button: `View all speakers`,
}

interface MeetOurSpeakersProps {
  speakers: SpeakerWithSessions[] | undefined
}
const MeetOurSpeakers: FC<MeetOurSpeakersProps> = ({ speakers }) => {
  const pageConfig = usePageConfig()
  const speakersLink = useDomainPath(`speakers`)
  const modalRef = useRef(null)
  const [selectedSpeaker, setSelectedSpeaker] = useState<SpeakerWithSessions | null>(null)
  const modalOpenState = useState(false)
  const [opened] = modalOpenState
  const { getControlOpenProps, getControlCloseProps } = useModal(modalRef, modalOpenState)

  const featuredSession =
    selectedSpeaker?.sessions.find((session) => session.featuredSession) ?? selectedSpeaker?.sessions[0] ?? null

  return (
    <>
      <SpeakerModal
        ref={modalRef}
        opened={opened}
        speaker={
          selectedSpeaker && {
            name: selectedSpeaker.speakerName,
            company: selectedSpeaker.speakerCompany,
            title: selectedSpeaker.speakerTitle,
            image: selectedSpeaker.speakerHeadshot.src,
            sessionTitle: featuredSession?.sessionTitle ?? ``,
            sessionDate: featuredSession?.sessionDateTimePdt ?? ``,
          }
        }
        onCloseClick={getControlCloseProps().onClick}
      >
        {selectedSpeaker && <RichText data={selectedSpeaker.speakerBio} />}
      </SpeakerModal>
      <SectionBase variant="centered" copyVariant="narrow">
        <StyledCopyText centered={true}>
          <CopyText.Overline>{copy.overline}</CopyText.Overline>
          <CopyText.Heading tag="h2">{copy.title}</CopyText.Heading>
          <CopyText.Description tag="p" variant="pLarge">
            {copy.description}
          </CopyText.Description>
        </StyledCopyText>
        <Section.Contents>
          <StyledColumnTrack columns={1} overrides={{ lg: 3 }}>
            {speakers?.map((speaker) => (
              <SpeakerCard
                key={speaker.speakerName}
                name={speaker.speakerName}
                company={speaker.speakerCompany}
                title={speaker.speakerTitle}
                image={speaker.speakerHeadshot.src}
                {...getControlOpenProps({
                  onClick() {
                    setSelectedSpeaker(speaker)
                  },
                })}
              />
            ))}
          </StyledColumnTrack>
        </Section.Contents>
        <Button variant="outlined" color="primary" size="large" href={getAbsoluteUrl(speakersLink, pageConfig)}>
          {copy.button}
        </Button>
      </SectionBase>
    </>
  )
}

const StyledColumnTrack = styled(ColumnTrack)`
  max-width: 1120px;
  margin: 0 auto;
`

const StyledCopyText = styled(CopyTextBase)`
  max-width: 854px;
  margin: 0 auto;
`

export default MeetOurSpeakers
